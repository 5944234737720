import './Footer.scss';
import { ReactComponent as LogoSvg } from '../../../icons/logo.svg';
import { ReactComponent as DiscordSvg } from '../../../icons/discord.svg';
import { ReactComponent as TelegramSvg } from '../../../icons/telegram.svg';
import { ReactComponent as TwitterSvg } from '../../../icons/twitter.svg';
import { ReactComponent as MediumSvg } from '../../../icons/medium.svg';

export const Footer = () => {
  return (
    <footer className={'footer'}>
      <div className='container footer__container'>
        <div className='footer__main'>
          <LogoSvg className='footer__logo' />
 
           <div className='footer__list footer__menu'>
            <div className='footer__subtitle text'>Menu</div>
            <div className='footer__list-item text'>Live show</div>
            <div className='footer__list-item text'>Leaderboard</div>
            <div className='footer__list-item text'>Results</div>
          </div> 
          <div className='footer__contacts'>
            <div className='footer__subtitle text'>Contacts</div>
            <div className='footer__list-item text'>@gmail.com</div>
            <div className='footer__list-item text'>youtube</div>
            <div className='footer__list-item text'>telegram</div>
          </div>
          <div className='footer__socials'>
            <div className='footer__subtitle text'>Social</div>
            <div className='footer__socials-list'>
              <a href={'/'} className='footer__socials-item'>
                <DiscordSvg />
              </a>
              <a href={'/'} className='footer__socials-item'>
                <TelegramSvg />
              </a>
              <a href={'/'} className='footer__socials-item'>
                <TwitterSvg />
              </a>
              <a href={'/'} className='footer__socials-item'>
                <MediumSvg />
              </a>
            </div>
          </div>
        </div>
        <div className='footer__copyright'>© Copyright crypto pets choise, {new Date().getFullYear()}</div>
      </div>
    </footer>
  );
};
