import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAccount, useDisconnect } from 'wagmi';
import { userAPI } from '../../../api/user';
import { profileState } from '../../../states/profileState';
import './WalletInfo.scss';

export const WalletInfo = () => {
  const [profile, setProfile] = useRecoilState(profileState);
  const [username, setUsername] = useState<string>(() => profile?.name as string);
  const [isEdit, setEdit] = useState<boolean>(false);
  const { disconnect } = useDisconnect();
  const handleNameSave = async () => {
    if (!isEdit) {
      setEdit(true);
      return;
    }
    try {
      const accessToken = window.localStorage.getItem('access_token');

      await userAPI.updateUsername({
        accessToken: accessToken!,
        username,
        wallet_addr: profile?.wallet_addr!,
      });

      const newProfile = (await userAPI.getUserByName(profile?.wallet_addr!)).data;
      setProfile(newProfile);
      setEdit(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (profile?.name) {
      setUsername(profile.name);
    }
  }, [profile]);
  return (
    <div className='wallet-info'>
      <h3>Profile</h3>

      <div className='wallet-info__edit-wrap'>
        <span className='wallet-info__wallet-id'>Name&nbsp;</span>
        <input
          className='wallet-info__wallet-id'
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
          disabled={!isEdit}
        />
        <button className='wallet-info__wallet-edit' onClick={handleNameSave}>
          {isEdit ? 'Save' : 'Edit'}
        </button>
      </div>
      {/* {Boolean(error) && <span className='wallet-info__error'>{error}</span>} */}
      <button
        className='wallet-info__logout'
        onClick={() => {
          disconnect();
          window.localStorage.removeItem('access_token');
          setProfile(null);
        }}
      >
        Log out
      </button>
    </div>
  );
};
