import { useRef, useState } from 'react';
import cn from 'classnames';
import './FaqItem.scss';

export const FaqItem = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpenContent, setIsOpenContent] = useState(false);

  return (
    <div className={'faq-item'}>
      <div onClick={() => setIsOpenContent(!isOpenContent)} className='faq-item__head'>
        <h4>How is the voting?</h4>
        <div className={cn('faq-item__plus', { 'faq-item__plus_white': isOpenContent })}>+</div>
      </div>

      <div
        style={{ height: isOpenContent ? `${contentRef.current?.offsetHeight}px` : '0' }}
        className={cn('faq-item__content-wrap', { 'faq-item__content-wrap_opened': isOpenContent })}
      >
        <div ref={contentRef} className='faq-item__content'>
          You can view them in 3D format or using Augmented Reality (AR), as well as take photos and videos
          with them. In the near future, you'll be able to mint them as NFTs and earn money from them.
          Additionally, you'll be able to customize and use them in games.
        </div>
      </div>
    </div>
  );
};
