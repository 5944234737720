import { ReactComponent as LogoSvg } from '../../icons/logo-horizontal.svg';
import './Sponsored.scss';

export const Sponsored = () => {
  return (
    <section className='sponsored'>
      <h3 className='container sponsored__container'>
        sponsored by <LogoSvg className='sponsored__logo' />
      </h3>
    </section>
  );
};
