import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sessionAPI } from '../../../api/session';
import { Button } from '../../../components/Button/Button';
import { petsState } from '../../../states/petsState';
import { sessionState } from '../../../states/sessionState';
import { petsAPI } from '../../../api/pets';
import { timerState } from '../../../states/timerState';
import { toast } from 'react-toastify';
import './AdminControls.scss';

export const AdminControls = () => {
  const [rangeValue, setRangeValue] = useState(1);
  const [session, setSession] = useRecoilState(sessionState);
  const [pets, setPets] = useRecoilState(petsState);
  const timer = useRecoilValue(timerState);
  const [streamLink, setStreamLink] = useState('');
  useEffect(() => {
    const bootstrap = async () => {
      const newSession = await sessionAPI.getCurrentSession();
      setSession(newSession);
    };
  }, [pets, setSession]);
  useEffect(() => {
    if (session) {
      setStreamLink(session?.stream_link!);
    }
  }, [session]);

  const handleStream = async () => {
    const access_token = localStorage.getItem('accessToken');
    try {
      if (session) {
        await sessionAPI.updateCurrentSession({
          access_token: access_token!,
          token_type: 'bearer',
          is_stream_live: !session.is_stream_live,
          update_session: true,
        });
      }
      const newSession = await sessionAPI.getCurrentSession();
      setSession(newSession);

      toast.success(`You have stoped stream`);
    } catch (e) {
      console.log(e);
      toast.error(`Something is wrong. Please, try again`);
    }
  };

  const onStartSession = async () => {
    setStreamLink('');
    const access_token = localStorage.getItem('accessToken');
    try {
      await sessionAPI.updateCurrentSession({
        fix_session_start: true,
        fix_session_end: undefined,
        update_session: undefined,
        start_voting: undefined,
        access_token: access_token!,
        token_type: 'bearer',
      });
      const newSession = await sessionAPI.getCurrentSession();
      setSession(newSession);
      toast.success(`Done!`);
    } catch (e) {
      console.error(e);
      toast.error(`Something is wrong. Please, try again`);
    }
  };

  const onStartVoting = async () => {
    const access_token = localStorage.getItem('accessToken');
    try {
      await sessionAPI.updateCurrentSession({
        access_token: access_token!,
        token_type: 'bearer',
        start_voting: true,
        voting_duration: rangeValue * 3600,
      });
      const session = await sessionAPI.getCurrentSession();
      setSession(session);
      toast.success(`Users can vote now`);
    } catch (e) {
      console.error(e);
      toast.error(`Something is wrong. Please, try again`);
    }
  };

  const onFinishSession = async () => {
    const access_token = localStorage.getItem('accessToken');
    try {
      await sessionAPI.updateCurrentSession({
        access_token: access_token!,
        token_type: 'bearer',
        fix_session_end: true,
      });
      const session = await sessionAPI.getCurrentSession();
      setSession(session);
      toast.success(`You have finished the session`);
    } catch (e) {
      console.error(e);
      toast.error(`Something is wrong. Please, try again`);
    }
  };

  const onUpVote = (animal_name: string) => {
    return async () => {
      const access_token = localStorage.getItem('accessToken');
      try {
        await petsAPI.updatePet({
          petName: animal_name,
          today_choice: 1,
          access_token: access_token!,
          token_type: 'bearer',
        });
        const { data: newPets } = await petsAPI.getAllPets();
        setPets(newPets);
        const session = await sessionAPI.getCurrentSession();
        setSession(session);
        toast.success(`${animal_name} chosed up`);
      } catch (e) {
        console.error(e);
        toast.error(`Something is wrong. Please, try again`);
      }
    };
  };

  const onDownVote = (animal_name: string) => {
    return async () => {
      const access_token = localStorage.getItem('accessToken');
      try {
        await petsAPI.updatePet({
          petName: animal_name,
          today_choice: 0,
          access_token: access_token!,
          token_type: 'bearer',
        });
        const { data: newPets } = await petsAPI.getAllPets();
        setPets(newPets);
        const session = await sessionAPI.getCurrentSession();
        setSession(session);
        toast.success(`${animal_name} chosed down`);
      } catch (e) {
        console.error(e);
        toast.error(`Something is wrong. Please, try again`);
      }
    };
  };

  const hadPetChoose = pets.reduce<Record<string, boolean>>(
    (acc, pet) => ({ ...acc, [pet.name]: Number.isInteger(pet.today_choice) }),
    {},
  );

  const canStartVoting = pets.some((pet) => !Number.isInteger(pet.today_choice));

  const handleUpdateLink = async () => {
    const access_token = localStorage.getItem('accessToken');
    try {
      await sessionAPI.updateCurrentSession({
        access_token: access_token!,
        token_type: 'bearer',
        stream_link: streamLink,
        update_session: true,
      });
      const session = await sessionAPI.getCurrentSession();
      setSession(session);

      toast.success(`You have updated session stream link`);
    } catch (e) {
      console.log(e);
      toast.error(`Something is wrong. Please, try again`);
    }
  };

  return (
    <div className='admin-controls'>
      <div className='admin-controls__left'>
        <p className='admin-controls__start-price'>
          start price: <span className='admin-controls__price'>{session?.start_price}</span> BTC
        </p>
        {session?.final_price && (
          <p className='admin-controls__final-price'>
            final price: <span className='admin-controls__price'>{session?.final_price}</span> BTC
          </p>
        )}
        <Button
          className='admin-controls__start-session'
          isFull
          onClick={onStartSession}
          disabled={session?.ended_at === null}
        >
          start session
        </Button>

        <div className='admin-controls__set-time'>
          <input
            type='text'
            className='admin-controls__input input input_bg'
            placeholder='Youtube link'
            value={streamLink}
            onChange={({ target: { value } }) => setStreamLink(value)}
          />
          <Button isFull onClick={handleUpdateLink}>
            update link
          </Button>
        </div>

        <Button isFull onClick={handleStream}>
          {session?.is_stream_live ? 'stop stream' : 'start stream'}
        </Button>

        <div className='admin-controls__time'>
          <input
            onChange={(e: any) => setRangeValue(e.target.value)}
            value={rangeValue}
            min={1}
            max={30}
            type='range'
            disabled={session?.is_voting_allowed || canStartVoting || session?.ended_at !== null}
            className={classNames('input-range', {
              'input-range_disabled':
                session?.is_voting_allowed || canStartVoting || session?.ended_at !== null,
            })}
          />
        </div>

        {session?.is_voting_allowed === null && <p>{rangeValue} hours</p>}

        <Button
          className='admin-controls__start-voting'
          disabled={session?.is_voting_allowed || canStartVoting || session?.ended_at !== null}
          onClick={onStartVoting}
          isFull
        >
          start voting
        </Button>

        <Button
          disabled={
            session?.is_voting_allowed ||
            session?.ended_at !== null ||
            canStartVoting ||
            session.voting_started_at === null
          }
          onClick={onFinishSession}
          isFull
          className='admin-controls__finish-btn'
        >
          finish session
        </Button>
      </div>

      <div className='admin-controls__choices'>
        {pets.map((pet) => {
          const isDisabled =
            hadPetChoose[pet.name] || session?.ended_at !== null || session.is_voting_allowed;
          return (
            <div className='admin-controls__pet-card' key={pet.name}>
              <p className='admin-controls__pet-name'>{pet.name}</p>
              {/* <img src={`/images/${pet.name.toLowerCase()}-card.png`} alt='' /> */}
              <Button
                disabled={isDisabled}
                onClick={onUpVote(pet.name)}
                className='admin-controls__upvote-btn'
                isFull
              >
                upvote
              </Button>
              <Button isFull disabled={isDisabled} onClick={onDownVote(pet.name)}>
                downvote
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
