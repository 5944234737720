import { useState } from 'react';
import { motion, Variants, PanInfo } from 'framer-motion';
import { ReactComponent as ArrowUp } from '../../../icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { ReactComponent as Cup } from '../../../icons/cup.svg';
import { petsState } from '../../../states/petsState';
import { Button } from '../../../components/Button/Button';
import { useRecoilValue } from 'recoil';

import './InfoSlider.scss';

const slideVariants: Variants = {
  current: {
    left: '50%',
    zIndex: 2,
    transform: 'translate(-50%, -50%)',
  },
  left: {
    left: '0%',
    zIndex: 1,
    width: 270,
    height: 290,
    filter: 'brightness(0.4)',
  },
  right: {
    left: '100%',
    zIndex: 1,
    width: 270,
    height: 290,
    filter: 'brightness(0.4)',
  },
};

export const InfoSlider = () => {
  const pets = useRecoilValue(petsState);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [canAnimate, setCanAnimate] = useState(true);
  const animateVariant = (index: number) => {
    if ((currentSlide + 1) % 3 === index) return 'right';
    if ((currentSlide + 2) % 3 === index) return 'left';
    return 'current';
  };
  const goToSlide = (index: number) => () => canAnimate && setCurrentSlide(index);
  const animSetter = (state: boolean) => () => setCanAnimate(state);

  const dragHandler = (_: any, info: PanInfo) => {
    if (info.delta.x < 0 && canAnimate) {
      setCurrentSlide((slide) => (slide + 1) % 3);
      return;
    }
    if (info.delta.x > 0 && canAnimate) {
      setCurrentSlide((slide) => (slide + 2) % 3);
      return;
    }
  };
  const petAccuracy = Number(eval(pets[currentSlide].accuracy));
  const strAccuracy = !Number.isNaN(petAccuracy) ? petAccuracy.toFixed(0) : '0';

  const petBiggestWin = Math.abs(pets[currentSlide].biggest_win).toFixed(0);
  const strBiggestWin = pets[currentSlide].biggest_win >= 0 ? `+ ${petBiggestWin}` : `- ${petBiggestWin}`;
  return (
    <section className='info-slider container'>
      <div className='info-slider__pet-info'>
        <div className='info-slider__pet-name'>
          {pets[currentSlide] && pets[currentSlide].name.toUpperCase()}
        </div>
        <div className='info-slider__pet-description'>
          They will each receive $100,000 and compete for the grand prize! And the stakes will be real.
        </div>
      </div>
      <div className='info-slider__pet-slider'>
        <motion.div
          className='info-slider__pet-slides'
          onDragStart={dragHandler}
          drag='x'
          dragElastic={0.01}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          {pets.map((pet, index) => {
            return (
              <motion.div
                variants={slideVariants}
                initial='current'
                animate={animateVariant(index)}
                className='info-slider__pet-slide'
                onAnimationStart={animSetter(false)}
                onAnimationComplete={animSetter(true)}
                onClick={goToSlide(index)}
                key={pet.name}
              >
                <img
                  className='info-slider__pet-slide__img'
                  src={`/images/place-${pet.name.toLowerCase()}.png`}
                  alt='name'
                  draggable={false}
                />
              </motion.div>
            );
          })}
        </motion.div>
      </div>
      {pets[currentSlide] && (
        <>
          <div className='info-slider__today-bet'>
            {pets[currentSlide].today_choice === 1 && <ArrowUp />}
            {pets[currentSlide].today_choice === 0 && <ArrowDown />}
            <h4 style={{ marginTop: '12px' }}>BET {pets[currentSlide].name.toUpperCase()} FOR TODAY</h4>
            {/* <Button className='info-slider__today-bet-btn'>Support a pet</Button>
            <p style={{ marginTop: '8px' }}>You can only vote for one pet</p> */}
          </div>
          <div className='info-slider__pet-stats'>
            <div className='info-slider__pet-place'>
              <div className='info-slider__pet-place-cup'>
                <Cup />
                <div className='info-slider__pet-place-info'>
                  <strong className='info-slider__pet-place-title'>PLACE</strong>
                  <h2 className='info-slider__pet-place-value'>1</h2>
                </div>
              </div>
              {/* <p className='info-slider__pet-place-description'>
                Текст про то что места меняются каждый раз после прохождения
              </p> */}
            </div>

            <div className='info-slider__pet-balance'>
              <p>Balance</p>
              <h2 className='info-slider__pet-balance-value'>
                {pets[currentSlide].total_balance.toFixed(0)} $
              </h2>
            </div>

            <div className='info-slider__pet-stats-row'>
              <div className='info-slider__pet-increase info-slider__pet-stat'>
                <p>Increased per game</p>
                <h3 className='info-slider__pet-stat-value'>+ 24 000 $</h3>
              </div>
              <div className='info-slider__pet-accuracy info-slider__pet-stat'>
                <p>Accuracy</p>
                <h3 className='info-slider__pet-stat-value'>{strAccuracy} %</h3>
              </div>
            </div>
            <div className='info-slider__pet-stats-row'>
              <div className='info-slider__pet-biggest info-slider__pet-stat'>
                <p>Biggest Win</p>
                <h3 className='info-slider__pet-stat-value'>{strBiggestWin} $</h3>
              </div>
              <div className='info-slider__pet-predictions info-slider__pet-stat'>
                <p>Correct predictions</p>
                <h3 className='info-slider__pet-stat-value'>{pets[currentSlide].accuracy}</h3>
              </div>
            </div>
          </div>
        </>
      )}
      <div className='info-slider__spot'></div>
    </section>
  );
};
