import classNames from 'classnames';
import { ReactNode } from 'react';

import './ButtonGroup.scss';

export interface ButtonGroupProps<T = string> {
  options: {
    label: ReactNode;
    value: T;
  }[];
  value: T;
  onChange: (value: T) => void;
}

export const ButtonGroup = ({ value, options, onChange }: ButtonGroupProps) => {
  return (
    <div className='button-group'>
      {options.map((option, index) => {
        return (
          <button
            className={classNames('button-group__btn', {
              'button-group__btn_active': option.value === value,
            })}
            onClick={() => {
              onChange && onChange(option.value);
            }}
            key={index}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};
