import { ReactComponent as ThinArrow } from '../../../icons/arrow-thin.svg';

import './Mission.scss';

export const Mission = () => {
  return (
    <section className='mission'>
      <div className='container'>
        <div className='mission__content'>
          <h3 className='mission__title'>
          BIT PET SHOW is a charity mission. With the help of our project, we want to draw public attention to the problem of treatment for pets and animals in shelters.
          </h3>
          <p className='mission__text mission__second'>
          Animals need your support. Thousands of shelters take care of our little brothers around the world. You can support our project by sharing with your friends. Please, choose a foundation or a shelter that you would like to support. 
Or perhaps our project will inspire you to give home to a pet from a shelter. Everyone needs some love.
          </p>
          <p className='mission__text mission__third'>
            We work in partnership with the charitable foundation «…» and the aggregator of pet shelters «…».
          </p>
          <div className='mission__found-block'>
            <div className='mission__found-title'>Aid fund</div>
            <img src='/images/ad_fund.png' alt='aid fund' />
          </div>
          <div className='mission__found-block mission__found-block_black'>
            <div className='mission__found-title'>Animal shelter</div>
            <img src='/images/animal_shelter.png' alt='animal shelter' />
          </div>
          <div className='mission__share-block'>
            Share
            <ThinArrow />
          </div>
        </div>
      </div>
    </section>
  );
};
