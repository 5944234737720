import { useRecoilValue } from 'recoil';
import { Broadcast } from '../../components/Broadcast/Broadcast';
import { historiesState } from '../../states/historiesState';
import { Results } from '../MainPage/Results/Results';
import './Previously.scss';

export const Previously = () => {
  const histories = useRecoilValue(historiesState);
  return (
    <div className='previously container'>
      <h2 className='previously__title'>Previously on this show</h2>
      <div className='previously__broadcasts'>
        {histories.map((history) => (
          <Broadcast {...history} />
        ))}
      </div>
      <div className='previously__results'>
        <Results />
      </div>
    </div>
  );
};
