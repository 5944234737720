import { InfoScreen } from '../../components/InfoScreen/InfoScreen';
import { ShareScreen } from '../../components/ShareScreen/ShareScreen';
import { Sponsored } from '../../components/Sponsored/Sponsored';
import { SubscribeForm } from '../../components/SubscribeForm/SubscribeForm';
import { Concept } from './Concept/Concept';
import { Faq } from './Faq/Faq';
import { FirstScreen } from './FirstScreen/FirstScreen';
import { Heroes } from './Heroes/Heroes';
import { How } from './How/How';
import { Last } from './Last/Last';
import { Leaders } from './Leaders/Leaders';
import './MainPage.scss';
import { Mission } from './Mission/Mission';
import { Results } from './Results/Results';
import { WhoSaid } from './WhoSaid/WhoSaid';
import { WinTabs } from './WinTabs/WinTabs';

export const MainPage = () => {
  return (
    <main>
      <FirstScreen />
      <Last />
      <WhoSaid />
      <Concept />
      <How />
      <Heroes />
      <WinTabs />
      <Results />
      <Last />
      <InfoScreen />
      <Leaders />
      <Mission />
      <ShareScreen />
      <SubscribeForm />
      <Faq />
      <Sponsored />
    </main>
  );
};
