import { Link } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { ReactComponent as Trends } from '../../icons/trands.svg';

import './Page404.scss';

export const Page404 = () => {
  return (
    <div className='not-found container'>
      <Trends />
      <div className='not-found__interactive'>
        <h3 className='not-found__title'>Oups! We have dropped something.</h3>
        <p>Go to the main page to continue visiting.</p>
        <Link to={'/'}>
          <Button isBig className='not-found__link-btn'>
            Go to Main page
          </Button>
        </Link>
      </div>
    </div>
  );
};
