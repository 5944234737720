import React from 'react';
import { StatusLabel } from '../StatusLabel/StatusLabel';
import { ReactComponent as LockSvg } from '../../icons/lock.svg';
import './CurrentRate.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { petsState } from '../../states/petsState';
import { sessionState } from '../../states/sessionState';

export const CurrentRate = () => {
  const [pets] = useRecoilState(petsState);
  const session = useRecoilValue(sessionState);

  return (
    <div className='current-rate'>
      <div className='current-rate__stop'>
        <LockSvg className='current-rate__lock' />
        <p className='current-rate__text'>BTC stop price:</p>
        <p className='current-rate__price'>{session?.start_price}</p>
      </div>

      <p className='current-rate__text current-rate__subtitle'>Current rate</p>

      <div className='current-rate__row'>
        {pets.map((item) => (
          <div className='current-rate__item' key={item.name}>
            <p className='current-rate__item-title'>{item.name}</p>
            {item.today_choice !== null ? (
              <StatusLabel isBig status={item.today_choice} text={item.today_choice === 1 ? 'up' : 'down'} />
            ) : (
              <span
                style={{
                  background: '#FFFB1F',
                  lineHeight: '32px',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  fontSize: '18px',
                  fontWeight: '600',
                  color: '#1A1C1E',
                }}
              >
                choosing
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
