import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { historiesAPI } from './api/histories';
import { petsAPI } from './api/pets';
import { sessionAPI } from './api/session';
import { Footer } from './components/layouts/Footer/Footer';
import { Header } from './components/layouts/Header/Header';
import { Admin } from './pages/Admin/Admin';
import { AnimalsPage } from './pages/AnimalsPage/AnimalsPage';
import { MainPage } from './pages/MainPage/MainPage';
import { Page404 } from './pages/Page404/Page404';
import { ProfilePage } from './pages/Profile/Profile';
import { historiesState } from './states/historiesState';
import { petsState } from './states/petsState';
import { timerState } from './states/timerState';
import { sessionState } from './states/sessionState';
import { lastSessionState } from './states/lastSessState';
import { Previously } from './pages/PreviouslyPage/Previously';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { profileState } from './states/profileState';
import { useAccount } from 'wagmi';
import { securityAPI } from './api/security';
import { userAPI } from './api/user';

function App() {
  const setPets = useRecoilState(petsState)[1];
  const setTimer = useRecoilState(timerState)[1];
  const [session, setSession] = useRecoilState(sessionState);
  const setPreviousSession = useRecoilState(lastSessionState)[1];
  const setHistories = useRecoilState(historiesState)[1];
  const [profile, setProfile] = useRecoilState(profileState);
  const { isConnected, address } = useAccount();

  useEffect(() => {
    const bootstrap = async () => {
      const session = await sessionAPI.getCurrentSession();
      setSession(session);
    };
    bootstrap();
  }, [profile]);

  useEffect(() => {
    const bootstrap = async () => {
      const { data: pets } = await petsAPI.getAllPets();
      const lastSession = await historiesAPI.getLastSession();
      let petProfit: Record<string, number> = {};
      if (lastSession) {
        petProfit = lastSession.pets_profits?.reduce<Record<string, number>>(
          (acc, pet) => ({ ...acc, [pet.pet_name]: pet.profit ?? 0 }),
          {},
        );
      }
      setPets(
        pets.map((pet) => {
          return {
            accuracy: pet.accuracy,
            biggest_win: pet.biggest_win,
            total_balance: pet.total_balance,
            total_votes: pet.total_votes,
            image: pet.image,
            name: pet.name,
            today_choice: pet.today_choice,
            number_of_winners: pet.number_of_winners,
            profit: petProfit[pet.name]
              ? petProfit[pet.name] > 0
                ? `+ ${petProfit[pet.name].toFixed()}`
                : `- ${Math.abs(petProfit[pet.name]).toFixed()}`
              : '0',
          };
        }),
      );
    };

    bootstrap();
  }, [setPets]);

  useEffect(() => {
    const bootstrap = async () => {
      const histories = (await historiesAPI.getAllSessions()).data;
      setHistories(histories);
    };
    bootstrap();
  }, [setHistories]);

  useEffect(() => {
    (async () => {
      const session = await sessionAPI.getCurrentSession();
      if (session.voting_started_at !== null && session.voting_ended_at !== null) {
        const endDate = new Date(session.voting_ended_at!);
        const timezoneDiff = new Date().getTimezoneOffset() * 60 * 1000;
        setTimer(new Date(endDate.getTime() - timezoneDiff));
      }
    })();
  }, [session, setTimer]);

  useEffect(() => {
    (async () => {
      const session = await sessionAPI.getPreviousSession();
      setPreviousSession(session);
    })();
  }, []);

  useEffect(() => {
    const bootstrap = async () => {
      try {
        const access_token = window.localStorage.getItem('access_token');
        if (access_token && isConnected && address) {
          const addr = (await securityAPI.check(access_token)).data.user_addr;
          const user = (await userAPI.getUserByName(addr)).data;
          setProfile(user);
        }
      } catch (e) {
        return false;
      }
    };
    bootstrap();
  }, [isConnected, setProfile, address]);

  return (
    <>
      <Header />
      <Routes>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'/admin'} element={<Admin />} />
        <Route path={'/profile/*'} element={<ProfilePage />} />
        <Route path={'/animals'} element={<AnimalsPage />} />
        <Route path={'/previous'} element={<Previously />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
      <Footer />
      <ToastContainer position='bottom-right' theme='dark' />
    </>
  );
}

export default App;
