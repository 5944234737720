import React from 'react';
import { ReactComponent as ArrowSvg } from '../../../icons/curved-arrow.svg';
import './WhoSaid.scss';
import { useRecoilState } from 'recoil';
import { petsState } from '../../../states/petsState';

export const WhoSaid = () => {
  const [pets] = useRecoilState(petsState);

  const getTotalProfit = () => {
    let summ: number = 0;

    pets.map((pet) => {
      if (pet.total_balance > 100000) {
        summ = pet.total_balance - 100000;
      }
    });

    return Math.round(summ);
  };

  return (
    <div className='who-said'>
      <div className='who-said__container container'>
        <h2 className='who-said__title h2_colored'>WHO SAYS PETS DON'T UNDERSTAND CRYPTO?</h2>

        <p className='who-said__text'>
        The greatest battle of all time begins. Each pet receives $100,000 for maintenance and will compete for the grand prize, a secure life for 5 years ahead and a loving owner. Vote for one of your favorites and win $10,000 or other prizes. We will prove that even animals can guess the prices of cryptocurrencies.
        </p>

        {getTotalProfit() > 0 && (
          <div className='who-said__stats'>
            <p className='who-said__stats-text'>Our pets have already earned:</p>
            <div className='who-said__stats-arrow'>
              <ArrowSvg />
            </div>
            <p className='who-said__stats-numbers'>+{getTotalProfit()}$</p>
          </div>
        )}
      </div>
    </div>
  );
};
