import './Concept.scss';

export const Concept = () => {
  return (
    <section id="about" className={'concept'}>
      <div className='container'>
        <h2 className={'concept__title'}>about the show</h2>
        <div className='concept__wrap'>
          <p className={'text'}>
          We support animals from the shelter and want to show the whole world that the most ordinary animals can become crypto prophets. And that they can not only earn, but also help others earn.
            <br />
            <br />
            Every day, the Dog (Doge), the Cat (Kitten) and the Hamster (Cho) face the choice: What will happen to the bitcoin rate in the next 24 hours? Animals place bets to raise or lower the rate.
            <br />
            <br />
            Each animal has a $100,000 virtual crypto account that increases or decreases depending on their choice. The show is hosted by charming host Lisey, who brings you the latest market news and helps the animals make their choice.
            <br />
            <br />
            Viewers earn by voting for animal crypto predictions. The total prize pool is $15,000. The winning pet will receive the life of their dreams, a media contract and the best provision for the next year.
          </p>
          <img className='concept__image' src='/images/cards-with-candles.png' alt='Cards' />
        </div>
      </div>
    </section>
  );
};
