import './Faq.scss';
import { FaqItem } from './FaqItem/FaqItem/FaqItem';

export const Faq = () => {
  return (
    <section className={'faq'}>
      <div className='container'>
        <h2 className={'faq__title'}>faaq</h2>
        <div className='faq__list'>
          <FaqItem />
          <FaqItem />
          <FaqItem />
        </div>
      </div>
    </section>
  );
};
