import { motion } from 'framer-motion';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ButtonGroup } from '../../../components/ButtonGroup/ButtonGroup';
import { DailyStats } from '../../../components/DailyStats/DailyStats';
import { petsState } from '../../../states/petsState';
import './WinTabs.scss';

enum TabValues {
  TOP_WINS = 'top_wins',
  NUMBER_OF_VOTES = 'number_of_votes',
  WINNERS_BY_DAY = 'winners_by_day',
  NUMBER_OF_GUESSED = 'number_of_guessed',
  NUMBER_OF_WINNERS = 'number_of_winners',
}

export const WinTabs = () => {
  const [filter, setFilter] = useState<TabValues>(TabValues.TOP_WINS);
  const pets = useRecoilValue(petsState);
  const maxVotes = pets.reduce((acc, { total_votes }) => {
    if (!acc) return total_votes;
    return acc < total_votes ? total_votes : acc;
  }, 0);
  const maxWinners = pets.reduce((acc, { number_of_winners }) => {
    if (!acc) return number_of_winners;
    return acc < number_of_winners ? number_of_winners : acc;
  }, 0);
  const maxGuessed = pets.reduce((acc, { accuracy }) => {
    const curValue = Number(eval(accuracy));
    if (!acc) return curValue;
    return acc < curValue ? curValue : acc;
  }, 0);

  return (
    <section className='win-tabs'>
      <div className='container'>
        <ButtonGroup
          options={[
            { label: 'Weekly top', value: TabValues.TOP_WINS },
            { label: 'Number of voters', value: TabValues.NUMBER_OF_VOTES },
            // { label: 'Winners by day', value: TabValues.WINNERS_BY_DAY },
            { label: 'Number of guessed bets', value: TabValues.NUMBER_OF_GUESSED },
            { label: 'Number of winners', value: TabValues.NUMBER_OF_WINNERS },
          ]}
          value={filter}
          onChange={(val) => setFilter(val as TabValues)}
        />
        {filter === TabValues.TOP_WINS && (
          <div className='win-tabs__tab'>
            <div className='win-tabs__tab-title'>
              <h3 className='win-tabs__title'>Weekly top</h3>
              <span>Data from June 1-7</span>
            </div>
            <div className='win-tabs__tab-content'>
              <div className='top-wins'>
                {pets.map((pet) => (
                  <div className='top-wins__card' key={pet.name}>
                    <div className='top-wins__profit'>{pet.profit} $</div>
                    <img
                      src={`/images/${pet.name.toLowerCase()}-vote.png`}
                      alt={pet.name}
                      className='top-wins__avatar'
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {filter === TabValues.NUMBER_OF_VOTES && (
          <div className='win-tabs__tab'>
            <div className='win-tabs__tab-title'>
              <h3 className='win-tabs__title'>Number of votes</h3>
              <span>For the whole season</span>
            </div>
            <div className='win-tabs__tab-content'>
              <div className='votes-number'>
                {pets.map(({ name, total_votes }) => {
                  return (
                    <div className='votes-number__card' key={name}>
                      <div className='votes-number__card-signature'>
                        <div
                          style={{
                            fontSize: '13px',
                            lineHeight: '21px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <span className='win-tabs__signature'>{total_votes}</span> followers
                        </div>
                      </div>
                      <motion.div
                        className='votes-number__frame'
                        animate={{ height: 240 + (325 - 240) * (total_votes / maxVotes) ?? 240 }}
                      >
                        <motion.img
                          src={`/images/${name.toLowerCase()}-card.png`}
                          alt={name}
                          className='votes-number__avatar'
                        />
                      </motion.div>
                      <div className='votes-number__pet-name'>{name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {filter === TabValues.NUMBER_OF_GUESSED && (
          <div className='win-tabs__tab'>
            <div className='win-tabs__tab-title'>
              <h3>Number of guessed bets</h3>
              <span>For the whole season</span>
            </div>
            <div className='win-tabs__tab-content'>
              <div className='votes-number'>
                {pets.map(({ name, accuracy }) => {
                  return (
                    <div className='votes-number__card' key={name}>
                      <div className='votes-number__card-signature'>
                        <div className='win-tabs__signature'>{accuracy}</div>
                      </div>
                      <motion.div
                        className='votes-number__frame'
                        animate={{ height: 240 + (325 - 240) * (eval(accuracy) / maxGuessed) ?? 240 }}
                      >
                        <motion.img
                          src={`/images/${name.toLowerCase()}-card.png`}
                          alt={name}
                          className='votes-number__avatar'
                        />
                      </motion.div>
                      <div className='votes-number__pet-name'>{name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {filter === TabValues.NUMBER_OF_WINNERS && (
          <div className='win-tabs__tab'>
            <div className='win-tabs__tab-title'>
              <h3>Number of winners</h3>
              <span>The number of people who won the choice of a particular pet</span>
            </div>
            <div className='win-tabs__tab-content'>
              <div className='winners'>
                {pets.map(({ number_of_winners, name }) => (
                  <motion.div
                    className='winners__card'
                    animate={{ height: 135 + (425 - 135) * (number_of_winners / maxWinners) ?? 135 }}
                    key={name}
                  >
                    <h4 className='winners__pet-name'>{name}</h4>
                    <div className='winners__winner-number'>
                      <span className='winners__number-value'>{number_of_winners}</span> winners
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        )}
        {filter === TabValues.WINNERS_BY_DAY && (
          <div className='win-tabs__tab'>
            <DailyStats />
          </div>
        )}
      </div>
    </section>
  );
};
