import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../../components/Button/Button';
import { ReactComponent as ArrowUp } from '../../../icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { ReactComponent as CurvedLine } from '../../../icons/curve-line.svg';
import { petsState } from '../../../states/petsState';
import { userAPI } from '../../../api/user';
import { sessionAPI } from '../../../api/session';
import { profileState } from '../../../states/profileState';

import './VoteScreen.scss';

export const VoteScreen = () => {
  const [pets] = useRecoilState(petsState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [canVote, setCanVote] = useState(false);

  useEffect(() => {
    const bootstrap = async () => {
      const { is_voting_allowed, ended_at } = await sessionAPI.getCurrentSession();
      if (profile) {
        const {
          data: { today_choice },
        } = await userAPI.getUserByName(profile.wallet_addr);
        setCanVote(Boolean(is_voting_allowed) && today_choice === null && ended_at === null);
      }
    };
    bootstrap();
  }, [profile]);

  return (
    <section className='vote-screen'>
      <CurvedLine className='vote-screen__background' />
      <div className='container'>
        <h2 className='vote-screen__title'>VOTE WITH PETS</h2>
        <div className='vote-screen__pet-cards'>
          {pets.map((pet) => (
            <div className='vote-screen__pet-card' key={pet.name}>
              <div className='vote-screen__card-header'>
                <div className='vote-screen__card-info'>
                  {pet.today_choice === 1 && <ArrowUp />}
                  {pet.today_choice === 0 && <ArrowDown />}
                  <strong>BET {pet.name.toUpperCase()} FOR TODAY</strong>
                </div>
                <img
                  src={`/images/${pet.name.toLowerCase()}-vote.png`}
                  alt={pet.name}
                  className='vote-screen__card-avatar'
                />
              </div>
              <div className='vote-screen__card-controls'>
                <Button
                  isBig
                  className='vote-screen__button'
                  onClick={async () => {
                    try {
                      const access_token = window.localStorage.getItem('access_token');
                      await userAPI.updateUser({
                        userAddress: profile?.wallet_addr!,
                        pet_name: pet.name,
                        accessToken: access_token!,
                        pet_choice: pet.today_choice as number,
                      });
                      const { data: newProfile } = await userAPI.getUserByName(profile?.wallet_addr!);
                      setProfile(newProfile);
                      setCanVote(false);
                    } catch (e) {
                      return false;
                    }
                  }}
                  disabled={!canVote}
                >
                  Choice
                </Button>
                <span>You can only vote for one pet</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
