import { Link } from 'react-router-dom';
import { ReactComponent as EyesSvg } from '../../icons/eyes.svg';
import { ReactComponent as UsersSvg } from '../../icons/users.svg';
import { IPet } from '../../states/petsState';
import { Button } from '../Button/Button';
import { StatusLabel } from '../StatusLabel/StatusLabel';
import './HeroCard.scss';

interface HeroCardProps {
  pet: IPet;
}

export const HeroCard = ({ pet }: HeroCardProps) => {
  const predictChance = Number(eval(pet.accuracy)) * 100;
  const accuracy = !Number.isNaN(predictChance) ? Math.round(predictChance) : 0;
  return (
    <div className='hero-card'>
      <div className='hero-card__left'>
        <h1 className='hero-card__title'>{pet.name}</h1>

        <p className='hero-card__text text'>
          {pet.name === 'Doggy' ? 'A cheerful boy who is ready to play at any time of the day. A great friend when you need warm support, and a faithful companion in your crypto passions!' : pet.name === 'Kitty' ? 'A cute and constantly hungry boy, he is always ready to predict something. He is 3 years old and he loves meatballs. If you scratch it behind the ear, you will hear a joyful purr.' : "A funny little guy who never sleeps at night. It's pretty good at burrowing and climbing. Always active and well-adjusted, Cho seems to know some crypto secrets."}
        </p>

        <div className='hero-card__followers'>
          <span className='hero-card__followers-title'>followers</span>
          <div className='hero-card__followers-stat'>
            <UsersSvg className='hero-card__followers-icon' />
            <span className='hero-card__followers-number'>{pet.total_votes}</span>
          </div>
        </div>

        <img className='hero-card__image' src={`images/${pet.name.toLowerCase()}-card.png`} alt='pet image' />
      </div>
      <div className='hero-card__right'>
        <div className='hero-card__stats hero-stats'>
          <EyesSvg className='hero-stats__eyes' />

          <div className='hero-stats__balance'>
            <p className='hero-stats__balance-title'>Balance</p>
            <p className='hero-stats__balance-number'>{`${pet.total_balance.toFixed(0)} $`}</p>
          </div>

          <div className='hero-stats__row'>
            {/* <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Overall score per game</p>
              <p className='hero-stats__text'>+24000 $</p>
            </div> */}
            <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Biggest Win</p>
              <p className='hero-stats__text'>{pet.biggest_win.toFixed(0)}</p>
            </div>
            <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Current Rate</p>
              <div className='hero-stats__text'>
                {pet.today_choice !== null ? (
                  <StatusLabel
                    isBig
                    status={pet.today_choice!}
                    text={pet.today_choice === 1 ? 'up' : 'down'}
                  />
                ) : (
                  <span
                    style={{
                      background: '#FFFB1F',
                      lineHeight: '32px',
                      borderRadius: '4px',
                      padding: '2px 8px',
                      fontSize: '24px',
                    }}
                  >
                    choosing
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='hero-stats__row'>
            <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Accuracy</p>
              <p className='hero-stats__text hero-stats__text_small'>{accuracy} %</p>
            </div>
            <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Correct predictions</p>
              <p className='hero-stats__text hero-stats__text_small'>{pet.accuracy}</p>
            </div>
            <div className='hero-stats__row-item'>
              <p className='hero-stats__title'>Number of winners</p>
              <p className='hero-stats__text hero-stats__text_small'>{pet.number_of_winners}</p>
            </div>
          </div>
        </div>

        <Link to='/animals'>
          <Button className='hero-card__button' isFull isBig>
          Place a bet
          </Button>
        </Link>

        <p className='hero-card__under-button'>
          Vote for Doggy and if he guesses the bet, you will earn 1 point
          <br />
          <br />
          You can only vote for one pet
        </p>
      </div>
    </div>
  );
};
