import { atom } from 'recoil';

export interface IPet {
  name: string;
  image: string;
  biggest_win: number;
  total_balance: number;
  total_votes: number;
  today_choice: null | 0 | 1;
  number_of_winners: number;
  profit?: string;
  accuracy: string;
}

export const petsState = atom<IPet[]>({
  key: 'petsState',
  default: [
    {
      name: 'Kitty',
      image: 'https://images.com/?name=kitty',
      accuracy: '0/0',
      biggest_win: 0.0,
      total_balance: 0,
      total_votes: 20,
      today_choice: null,
      number_of_winners: 0,
    },
    {
      name: 'Doggy',
      image: 'https://images.com/?name=kitty',
      accuracy: '0/0',
      biggest_win: 0.0,
      total_balance: 0,
      total_votes: 40,
      today_choice: null,
      number_of_winners: 0,

    },
    {
      name: 'Cho',
      image: 'https://images.com/?name=kitty',
      accuracy: '0/0',
      biggest_win: 0.0,
      total_balance: 0,
      total_votes: 60,
      today_choice: null,
      number_of_winners: 0,
    },
  ],
});
