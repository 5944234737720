import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { securityAPI } from '../../api/security';
import { sessionAPI } from '../../api/session';
import { Button } from '../../components/Button/Button';
import { sessionState } from '../../states/sessionState';
import { AdminControls } from './AdminControls/AdminControls';
import './Admin.scss';

export const Admin = () => {
  const [loginText, setLoginText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [isSigned, setSigned] = useState<boolean>();
  const setSession = useRecoilState(sessionState)[1];
  const signInHandler = async () => {
    await securityAPI
      .signInAdmin(loginText, passwordText)
      .then((res) => {
        console.log(res);
        localStorage.setItem('accessToken', res.data.access_token);
        localStorage.setItem('token_type', res.data.token_type);
        setSigned(true);
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    setSigned(localStorage.getItem('accessToken') ? true : false);
  }, []);

  return (
    <div className='admin'>
      <div className='admin__container container'>
        <h2 className='admin__title'>{isSigned ? 'Admin panel' : 'Admin auth'}</h2>

        {!isSigned && (
          <div className='admin__auth'>
            <input
              className='admin__input input'
              value={loginText}
              onChange={(e: any) => setLoginText(e.target.value)}
              type='text'
              placeholder='Login'
            />
            <input
              className='admin__input input'
              value={passwordText}
              onChange={(e: any) => setPasswordText(e.target.value)}
              type='password'
              placeholder='Password'
            />

            <Button className='admin__auth-button' onClick={signInHandler}>
              Sign in
            </Button>
          </div>
        )}

        {isSigned && <AdminControls />}
      </div>
    </div>
  );
};
