import classNames from 'classnames';
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAccount } from 'wagmi';
import { ReactComponent as AvatarIcon } from '../../../icons/avatar.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { ReactComponent as LogoDark } from '../../../icons/logo-dark.svg';
import { ReactComponent as LogoLight } from '../../../icons/logo-light.svg';
import { ReactComponent as MenuIcon } from '../../../icons/menu.svg';
import { profileState } from '../../../states/profileState';
import { WalletSidebar } from '../../WalletSidebar/WalletSidebar';
import './Header.scss';
import { MetamaskButton } from './MetamaskButton/MetamaskButton';

export const Header = () => {
  const { isConnected, address } = useAccount();
  const [profile, setProfile] = useRecoilState(profileState);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuList = [
    {
      name: 'About the show',
      link: '#about',
      isVisible: true,
    },
    {
      name: 'Support a pet',
      link: '/',
      isVisible: true,
    },
    {
      name: 'Live show',
      link: '/',
      isVisible: true,
    },
  ];
  const menuItems = menuList.map((item, i) => {
    return (
      <NavLink className='header__nav-item' key={i} to={item.link} onClick={() => setMenuVisible(false)}>
        {item.name}
      </NavLink>
    );
  });

  const currentURL = useLocation();

  const isLight = ['/animals'].includes(currentURL.pathname);

  return (
    <header className={classNames('header', { blue: isLight })} id='header'>
      <div className='container header__container'>
        <Link to={'/'}>
          {isLight && <LogoLight />}
          {!isLight && <LogoDark />}
        </Link>
        <nav className='header__nav-list'>{menuItems}</nav>
        <div className='header__user-info'>
          {!profile && <MetamaskButton />}
          {profile && (
            <>
              <p className='header__user-info-label'>{profile.wallet_addr}</p>
              <Link to={'/profile'}>
                <AvatarIcon />
              </Link>
            </>
          )}
        </div>
        <button className='header__menu-btn' onClick={() => setMenuVisible(true)}>
          <MenuIcon />
        </button>
        <div className={classNames('header__drawer', { header__drawer_visible: isMenuVisible })}>
          <div className='header__drawer-heading'>
            <button className='header__menu-btn' onClick={() => setMenuVisible(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className='header__drawer-content'>
            <nav className='header__nav-list header__nav-list_drawer'>{menuItems}</nav>
          </div>
          <div className='header__drawer-delimiter' />
          <div className='header__user-info header__user-info_drawer'>
            {!profile && <MetamaskButton />}
            {profile && (
              <>
                <div className='header__drawer-profile'>
                  <Link to={'/profile'}>
                    <AvatarIcon />
                  </Link>
                  <p className='header__user-info-label'>{profile.wallet_addr}</p>
                </div>
                <WalletSidebar onMenuRedirect={() => setMenuVisible(false)} />
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
