import { useRecoilState } from 'recoil';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { securityAPI } from '../../../../api/security';
import { userAPI } from '../../../../api/user';
import { ReactComponent as MetamaskSvg } from '../../../../icons/MetaMask.svg';
import { profileState } from '../../../../states/profileState';
import './MetamaskButton.scss';

// const connector = new MetaMaskConnector();

export const MetamaskButton = () => {
  const { disconnect } = useDisconnect();
  const { connectAsync, connectors } = useConnect();
  const connector = connectors[0];
  const { address, isConnected } = useAccount();
  const setProfile = useRecoilState(profileState)[1];

  const { signMessageAsync } = useSignMessage({
    async onSuccess(data, variables) {
      if (address && isConnected) {
        const { access_token, token_type } = (
          await securityAPI.signIn({
            nonce: variables.message,
            signature: data,
            wallet_addr: address,
          })
        ).data;
        window.localStorage.setItem('access_token', `${token_type} ${access_token}`);
        const { data: profileUpdate } = await userAPI.getUserByName(address);
        setProfile(profileUpdate);
      }
    },
  });

  const handleAuth = async () => {
    try {
      const res = await connectAsync({ connector });
      const address = res.account;
      const isUserExists: boolean = await userAPI
        .getUserByName(address)
        .then(() => true)
        .catch(() => false);
      let nonce = '';
      if (isUserExists) {
        nonce = (await securityAPI.getNonce(address)).data.nonce;
      } else {
        nonce = (await securityAPI.registerUser(address)).data.nonce;
      }
      const signedMessage = await signMessageAsync({ message: nonce });
      return signedMessage;
    } catch (e) {
      disconnect();
    }
  };

  return (
    <div onClick={handleAuth} className='metamask-button'>
      <MetamaskSvg className={'metamask-button__icon'} /> Connect wallet
    </div>
  );
};
