import { Button } from '../Button/Button';
import { ReactComponent as RoundArrow } from '../../icons/arrow_round.svg';
import { ReactComponent as SpinArrow } from '../../icons/arrow_spin.svg';
import './ShareScreen.scss';

export const ShareScreen = () => {
  return (
    <section className='share-screen container'>
      <h2 className='share-screen__title'>Share and get bonuses</h2>
      <div className='share-screen__cards'>
        <div className='share-screen__card'>
          <div className='share-screen__card-info'>
            <h3>Tell about us</h3>
            <p className='share-screen__card-text'>
              You can share news about our project on your social networks so that as many people as possible
              know about the project
            </p>
          </div>
          <img src='/images/share-eyes.png' alt='eyes' className='share-screen__card-img' />
          <RoundArrow className='share-screen__round-arrow' />
        </div>
        <div className='share-screen__card share-screen__coins'>
          <div className='share-screen__card-info'>
            <h3>Get points</h3>
            <p className='share-screen__card-text'>
            Get extra points to your rating and increase your chances of winning with our pets
            </p>
          </div>
          <img src='/images/share-coins.png' alt='eyes' className='share-screen__card-img' />
          <SpinArrow className='share-screen__spin-arrow' />
        </div>
        <div className='share-screen__card'>
          <div className='share-card__card-info'>
            <h3 className='share-screen__card-title_coloured'>Together we do great things!</h3>
            <p className='share-screen__card-text'>
            The more you share, the more people are involved in the mission of keeping animals healthy and safe around the world
            </p>
          </div>
          <Button className='share-screen__share-btn'>Share</Button>
        </div>
      </div>
    </section>
  );
};
