import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import './How.scss';

const howCardsList = [
  {
    title: 'Watch the show',
    text: 'Every day at 14:00 (GMT), 10:00 UTC−4 EDT, 23:00 Asia/Tokyo starting from September 12 and ending on October 12',
    buttonText: 'Watch',
  },
  {
    title: 'Vote and win',
    text: 'Vote for your favorite pet and participate in the prize draw.',
    buttonText: 'Place a bet',
    href: '/animals',
  },
  {
    title: 'Promote to your friends',
    text: 'Be active in the community. Tell your friends about our show and mission. Get bonuses.',
    buttonText: 'Share',
  },
];

export const How = () => {
  const cards = howCardsList.map((card, i) => {
    return (
      <div key={i} className='how__card how-card'>
        <div>
          <h2 className={'how-card__number'}>0{i + 1}</h2>
          <h3 className={'how-card__title'}>{card.title}</h3>
          <p className={'how-card__text text'}>{card.text}</p>
        </div>

        {card.href && (
          <Link to={card.href}>
            <Button className='how-card__button'>{card.buttonText}</Button>
          </Link>
        )}
      </div>
    );
  });

  return (
    <section className={'how'}>
      <div className='container'>
        <h2 className='how__title'>how to participate and win</h2>
        <div className='how__list'>
          <img className={'how__waves'} src='/images/line-waves.svg' alt='Waves' />
          {cards}
        </div>
      </div>
    </section>
  );
};
