import ReactPlayer from 'react-player';
import { useRecoilValue } from 'recoil';
import { CurrentRate } from '../../../components/CurrentRate/CurrentRate';
import { sessionState } from '../../../states/sessionState';
import { lastSessionState } from '../../../states/lastSessState';
import './Last.scss';
import { TimerBanner } from '../../../components/TimerBanner/TimerBanner';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';

export const Last = () => {
  const session = useRecoilValue(sessionState);
  const lastSession = useRecoilValue(lastSessionState);
  const url = session?.is_stream_live === true ? session?.stream_link : lastSession?.stream_link;
  return (
    <div className={'last'}>
      <div className='container'>
        {url && (
          <>
            <h2 className={'last__title'}>{session?.is_stream_live ? 'Live' : 'Previously'} on this show</h2>

            <div className='last__banner'>
              <ReactPlayer url={url} width={'100%'} height={'auto'} style={{ aspectRatio: '16 / 9' }} />
            </div>
          </>
        )}
        <div className='last__row'>
          <CurrentRate />
          <div className='last__right'>
            <TimerBanner isStyled type="end" />
            <Link to='/animals'>
              <Button isBig className='hero-card__button' isFull>
                Vote with pets
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
