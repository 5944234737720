import axios from 'axios';
import { apiURL } from '../constants/apiURL';
import { IUserHistory } from '../states/userHistories';

export interface IHistory {
  date: string;
  start_price: number;
  final_price: number;
  pets_profits: {
    pet_name: string;
    profit: number;
  }[];
  stream_link?: string;
}

const histories = axios.create({
  baseURL: apiURL + 'histories',
  withCredentials: false,
});

export const historiesAPI = {
  getLastSession: async () => {
    return (
      await histories.get<IHistory[]>('/session', {
        params: {
          page: 0,
          num: 1,
        },
      })
    ).data[0];
  },
  getAllSessions: async () => {
    return await histories.get<IHistory[]>('/session', {});
  },
  getUserSessions: async (user_addr: string) => {
    return await histories.get<IUserHistory[]>(`/users/${user_addr}`);
  },
};
