import { Route, Routes, useNavigate } from 'react-router-dom';
import { Leaderboard } from '../../components/Leaderboard/Leaderboard';
import { ResultsHistory } from '../../components/ResultsHistory/ResultsHistory';
import { WalletSidebar } from '../../components/WalletSidebar/WalletSidebar';
import { WalletInfo } from './WallletInfo/WalletInfo';

import './Profile.scss';
import { useAccount } from 'wagmi';

export const ProfilePage = () => {
  const { isConnected, address } = useAccount();
  const navigate = useNavigate();
  if (!isConnected || !address) {
    navigate('/');
  }
  return (
    <main>
      <div className='container'>
        <div className='profile-page'>
          <Routes>
            <Route
              path='/'
              element={
                <div className='profile-page__content-wrap'>
                  <WalletInfo />
                </div>
              }
            />
            <Route
              path='/history'
              element={
                <div className='profile-page__table-wrap'>
                  <ResultsHistory />
                </div>
              }
            />
            <Route
              path='/leaderboard'
              element={
                <div className='profile-page__table-wrap'>
                  <Leaderboard />
                </div>
              }
            />
          </Routes>

          <div className='profile-page__sidebar'>
            <WalletSidebar />
          </div>
        </div>
      </div>
    </main>
  );
};
