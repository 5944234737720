import React from 'react';
import './DailyStats.scss';

export const DailyStats = () => {
  return (
    <div className='daily-stats'>
      <h2 className='daily-stats__title'>Winners by day</h2>

      <div className='daily-stats__won'>
        <div className='daily-stats__cell'></div>
      </div>
      <hr />
      <div className='daily-stats__lost'></div>
      <div className='daily-stats__timeline'>
        <span className='daily-stats__timeline-date'>June 7</span>
        <span className='daily-stats__timeline-date'>June 8</span>
        <span className='daily-stats__timeline-date'>June 10</span>
        <span className='daily-stats__timeline-date'>June 11</span>
        <span className='daily-stats__timeline-date'>June 12</span>
      </div>
    </div>
  );
};
