import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { ReactComponent as Top1Svg } from '../../icons/top-1.svg';
import { ReactComponent as Top2Svg } from '../../icons/top-2.svg';
import { ReactComponent as Top3Svg } from '../../icons/top-3.svg';
import { usersState } from '../../states/usersState';
import { userAPI } from '../../api/user';

import './Leaderboard.scss';

export const Leaderboard = () => {
  const [users, setUsers] = useRecoilState(usersState);
  const [viewAll, setViewAll] = useState(false);
  useEffect(() => {
    const bootstrap = async () => {
      const { data } = await userAPI.getAllUsers(0, 20);
      data.sort((first, second) => first.rating - second.rating);
      setUsers(data);
    };
    bootstrap();
  }, [setUsers]);

  return (
    <div className='leaderboard__wrap'>
      <div className='leaderboard__container'>
        <div className='leaderboard__title'>
          <h3>Leaderboard</h3>
          {/* <span className={'text'}>Total participants: 345</span> */}
        </div>

        <div className='leaderboard__table-wrap'>
          <div className='leaderboard__table leaderboard-table'>
            <div className='leaderboard-table__row leaderboard-table__row_head'>
              <div className='leaderboard-table__place'>Place</div>
              <div className='leaderboard-table__name'>Name</div>
              <div className='leaderboard-table__points'>Points</div>
            </div>
            {users
              .filter((leader, index) => index < 5 || (viewAll && index < 100))
              .map((leader) => {
                return (
                  <div className='leaderboard-table__row' key={leader.rating}>
                    <div className='leaderboard-table__place'>
                      {leader.rating === 1 && <Top1Svg />}
                      {leader.rating === 2 && <Top2Svg />}
                      {leader.rating === 3 && <Top3Svg />}
                      {leader.rating > 3 && leader.rating}
                    </div>
                    <div className='leaderboard-table__name'>{leader.name}</div>
                    <div className='leaderboard-table__points'>{leader.points}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <button className='leaderboard__view-all' onClick={() => setViewAll(!viewAll)}>
        {!viewAll ? 'View all' : 'Hide'}
      </button>
    </div>
  );
};
