import { Leaderboard } from '../../../components/Leaderboard/Leaderboard';
import './Leaders.scss';

export const Leaders = () => {
  return (
    <section className='leaders'>
      <div className='container leaders__container'>
        <Leaderboard />
      </div>
    </section>
  );
};
