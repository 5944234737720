import axios from 'axios';
import { apiURL } from '../constants/apiURL';

export interface IUser {
  name: string;
  wallet_addr: string;
  email: string;
  rating: number;
  today_choice: {
    pet_name: string;
    pet_choice: number;
  } | null;
  points: number;
}

const user = axios.create({
  baseURL: apiURL + 'users',
  withCredentials: false,
});

export const userAPI = {
  createUser: (address: string) => {
    return user.post<IUser>(``, {
      name: '',
      wallet_addr: address,
      email: '',
    });
  },

  getAllUsers: (page: number, num: number) => {
    return user.get<IUser[]>('', {
      params: {
        page: page,
        num: num,
      },
    });
  },

  getUserByName: (userAddress: string) => {
    return user.get<IUser>(`/${userAddress}`);
  },

  updateUser: (data: { userAddress: string; pet_name: string; accessToken: string; pet_choice: number }) => {
    return user.put(
      `/${data.userAddress}`,
      {
        today_choice: {
          pet_name: data.pet_name,
          pet_choice: data.pet_choice,
        },
      },
      { headers: { Authorization: data.accessToken } },
    );
  },

  deleteUser: (userAddress: string) => {
    return user.delete(`/${userAddress}`);
  },

  updateUsername: (data: { username: string; accessToken: string; wallet_addr: string }) => {
    return user.put(
      `/${data.wallet_addr}`,
      { name: data.username },
      { headers: { Authorization: data.accessToken } },
    );
  },
};
