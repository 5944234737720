import axios from 'axios';
import { apiURL } from '../constants/apiURL';

const api = axios.create({ baseURL: apiURL + 'security/users' });
const apiAdmin = axios.create({ baseURL: apiURL + 'security/admins' });

interface SignInResponse {
  message: string;
  access_token: string;
  token_type: string;
}

export const securityAPI = {
  async registerUser(wallet_addr: string) {
    return await api.post<{ nonce: string }>(
      '/register',
      {},
      {
        params: {
          wallet_addr,
        },
      },
    );
  },
  async getNonce(wallet_addr: string) {
    return await api.get<{ nonce: string }>(`/${wallet_addr}/nonce`);
  },
  async signIn(data: { nonce: string; wallet_addr: string; signature: string }) {
    return await api.post<SignInResponse>('/signin', data);
  },
  async signInAdmin(username: string, password: string) {
    return await apiAdmin.post<SignInResponse>('/signin', {
      username: username,
      password: password,
    });
  },
  async check(access_token: string) {
    return await api.get('/token/check', { headers: { Authorization: access_token } });
  },
};
