import { useRecoilValue } from 'recoil';
import { sidebarMenuItems } from '../../constants/sidebarMenu';
import PointsIcon from '../../icons/points.svg';
import RatingIcon from '../../icons/rating.svg';
import { profileState } from '../../states/profileState';
import { Button } from '../Button/Button';
import { MenuItem } from '../MenuItem/MenuItem';
import './WalletSidebar.scss';

export interface WalletSidebarProps {
  onMenuRedirect?: () => void;
}

export const WalletSidebar = ({ onMenuRedirect }: WalletSidebarProps) => {
  const profile = useRecoilValue(profileState);
  return (
    <div className='wallet-sidebar'>
      <div className='wallet-sidebar__stat-wrap'>
        <div className='wallet-sidebar__stat-card'>
          <div className='wallet-sidebar__stat-title'>Points</div>
          <div className='wallet-sidebar__stat-value'>{profile && profile.points}</div>
          <div className='wallet-sidebar__stat-icon'>
            <img src={PointsIcon} alt="points Icon" />
          </div>
        </div>

        <div className='wallet-sidebar__stat-card'>
          <div className='wallet-sidebar__stat-title'>Rating number</div>
          <div className='wallet-sidebar__stat-value'>{profile && profile.rating}</div>
          <div className='wallet-sidebar__stat-icon'>
            <img src={RatingIcon} alt="points Icon" />
          </div>
        </div>
      </div>

      <span className='wallet-sidebar__text'>Share and get 5 extra points</span>

      <Button>
        Share
      </Button>

      <div className='wallet-sidebar__delimiter' />

      {sidebarMenuItems.map(({ title, href }) => (
        <MenuItem key={title} href={href} onClick={onMenuRedirect}>
          {title}
        </MenuItem>
      ))}
    </div>
  );
};
