import { atom } from 'recoil';
import { IUser } from '../api/user';

export const usersState = atom<IUser[]>({
  key: 'usersState',
  default: [
    {
      wallet_addr: '0x754C2D04Da15310BC1B850D28d179e21565e52Ea',
      name: 'timaracov',
      rating: 1,
      email: '',
      points: 0.0,
      today_choice: { pet_choice: 1, pet_name: 'cho' },
    },
  ],
});
