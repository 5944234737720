import './Subscribe.scss';

export const Subscribe = () => {
  return (
    <div className='subscribe-form'>
      <h3>Stay up to date with the latest news</h3>
      <p className='subscribe-form__text'>Leave your contacts so you don't miss the next broadcast</p>
      <div className='subscribe-form__input-wrap'>
        <input type='text' className='subscribe-form__input' placeholder='Your email' />
        <button className='subscribe-form__send-btn'>Send</button>
      </div>
    </div>
  );
};
