import { useRecoilValue } from 'recoil';
import { TimerBanner } from '../../../components/TimerBanner/TimerBanner';
import { ReactComponent as DiscordContact } from '../../../icons/discord-contact.svg';
import { ReactComponent as MediumContact } from '../../../icons/medium-contact.svg';
import { ReactComponent as TelegramContact } from '../../../icons/telegram-contact.svg';
import { ReactComponent as TwitterContact } from '../../../icons/twitter-contact.svg';
import { ReactComponent as WatchOnYoutube } from '../../../icons/watchthisonyt.svg';
import { petsState } from '../../../states/petsState';
import './FirstScreen.scss';
import classNames from 'classnames';
import { Button } from '../../../components/Button/Button';

export const FirstScreen = () => {
  const pets = useRecoilValue(petsState);

  const petCards = pets.map((pet, i) => {
    return (
      pet && (
        <div
          key={i}
          className={classNames('first-pet-card', {
            'first-pet-card_white': i == 1,
            'first-pet-card_yellow': i == 2,
          })}
        >
          <div className='first-pet-card__info-wrap'>
            <div className='first-pet-card__info'>
              <div className='first-pet-card__name'>{pet.name}</div>
              <div className='first-pet-card__balance'>
                <span>$ {pet.total_balance.toFixed(0)}</span>
                <p className='first-pet-card__label'>Balance for today</p>
              </div>
              <div className='first-pet-card__place'>
                <div className='first-pet-card__place-round'>
                  <p className='first-pet-card__value'>2nd</p>
                </div>
                <p className='first-pet-card__label'>place</p>
              </div>
              <div className='first-pet-card__results-wrap'>
                <div className='first-pet-card__followers'>
                  <p className='first-pet-card__value'>{pet.total_votes}</p>
                  <p className='first-pet-card__label'>Followers</p>
                </div>
                <div className='first-pet-card__last_result'>
                  <p className='first-pet-card__value'>{pet.profit} $</p>
                  <p className='first-pet-card__label'>Latest result</p>
                </div>
              </div>
            </div>
          </div>
          <div className={'first-pet-card__avatar'}>
            <img src={`/images/${pet.name.toLowerCase()}-card.png`} alt={pet.name} />
          </div>
        </div>
      )
    );
  });

  return (
    <section className='first-screen'>
      <div className='container first-screen__container'>
        <div className='first-screen__info-wrap'>
          <h1 className='first-screen__title'>BIT PET LIVE SHOW</h1>
          <p style={{ color: '#837D6F', fontSize: '20px', fontWeight: '400' }}>Online charity show with prizes</p>
          <p className='first-screen__description'>
          Doggie, Kitty and Cho predict the price of bitcoin every day. The winner will receive the grand prize. Vote and win!
          </p>
          <div className='first-screen__contacts'>
          <p style={{ fontSize: '16px', fontWeight: '400' }}>Follow us on</p>
          <div className='first-screen__contacts-wrap'>
            <a href='' className='first-screen__contact'>
              <DiscordContact className='first-screen__contact-icon' />
            </a>
            <a href='' className='first-screen__contact'>
              <TelegramContact className='first-screen__contact-icon' />
            </a>
            <a href='' className='first-screen__contact'>
              <TwitterContact className='first-screen__contact-icon' />
            </a>
            <a href='' className='first-screen__contact'>
              <MediumContact className='first-screen__contact-icon' />
            </a>
          </div>
        </div>
        </div>
      <div className='first-screen__content-wrap'>
        <div className='first-screen__content-row'>{pets && petCards}</div>
        <div className='first-screen__content-row'>
          <h3 className='first-screen__banner'>Will the bet of our pets play?</h3>
          <TimerBanner isArrow={true} type='in' />
          <a href=''>
            <WatchOnYoutube />
          </a>
        </div>
        <Button isBig className='first-screen__remind-btn'>
            Set reminder
          </Button>
      </div>
      </div>
    </section>
  );
};
