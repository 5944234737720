import { atom } from 'recoil';

export interface IUserHistory {
  user_addr: string;
  users_choice: {
    pet_name: string;
    pet_choice: 0 | 1 | null;
  };
  is_pet_won: boolean;
  points_profit: number;
  date: string;
  id: string;
}

export const userHistories = atom<IUserHistory[]>({ key: 'userHistoriesState', default: [] });
