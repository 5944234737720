import { Leaderboard } from '../../../components/Leaderboard/Leaderboard';

import './LeaderboardScreen.scss';

export const LeaderboardScreen = () => {
  return (
    <section className='leaderboard-screen container'>
      <div className='leaderboard-screen__content'>
        <Leaderboard />
      </div>
    </section>
  );
};
