import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ReactComponent as ArrowRound } from '../../icons/arrow_round.svg';
import { sessionState } from '../../states/sessionState';
import { timerState } from '../../states/timerState';
import './TimerBanner.scss';

interface TimerBannerProps {
  isStyled?: boolean;
  isArrow?: boolean;
  type: 'end' | 'in'

}

export const TimerBanner = ({ isStyled, isArrow, type }: TimerBannerProps) => {
  const timer = useRecoilValue(timerState);
  const session = useRecoilValue(sessionState);
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff(timer.getTime() - Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const hours = String(Math.floor(diff / 1000 / 60 / 60)).padStart(2, '0');
  const mins = String(Math.floor(diff / 1000 / 60) % 60).padStart(2, '0');
  const seconds = String(Math.floor(diff / 1000) % 60).padStart(2, '0');

  return (
    <div className='timer-banner'>
      <div className='timer-banner__row'>
        <h4 className='timer-banner__title'>{type === 'in' ?  'Find out in:' : 'The voting ends in:'}</h4>
      </div>
      {timer && session?.ended_at === null && diff >= 0 ? (
        <>
          <div className='timer-banner__row'>
            <p className='timer-banner_label'>hours</p>
            <p className='timer-banner_label'>minutes</p>
            <p className='timer-banner_label'>seconds</p>
          </div>
          <div className='timer-banner__row timer-banner__time-row'>
            <span className='timer-banner__time'>{hours}</span>
            <span className='timer-banner__time'>:</span>
            <span className='timer-banner__time'>{mins}</span>
            <span className='timer-banner__time'>:</span>
            <span className='timer-banner__time'>{seconds}</span>
          </div>
        </>
      ) : (
        <div className='timer-banner__row'>Voting ended</div>
      )}
      {isArrow && <ArrowRound className='timer-banner__arrow' />}
    </div>
  );
};
