import React from 'react';
import cn from 'classnames';
import './StatusLabel.scss';

interface StatusLabelProps {
  isBig?: boolean;
  status: number;
  className?: string;
  text?: string;
}

export const StatusLabel = ({ status, className, text, isBig }: StatusLabelProps) => {
  return (
    <div
      className={cn('status-label', className, {
        'status-label_green': status === 1,
        'status-label_red': status === 0,
        'status-label_big': isBig,
      })}
    >
      {text}
    </div>
  );
};
