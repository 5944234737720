import { Subscribe } from '../Subscribe/Subscribe';

import './SubscribeForm.scss';

export const SubscribeForm = () => {
  return (
    <section className='form-screen'>
      <Subscribe />
    </section>
  );
};
