import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { IHistory } from '../../api/histories';
import './Boradcast.scss';

export interface BroadcastProps extends IHistory {}

export const Broadcast = ({ date, pets_profits, stream_link }: BroadcastProps) => {
  return (
    <div className='broadcast'>
      <div className='broadcast__header'>
        <span className='broadcast__date'>June 12</span>
        <span className='broadcast__title'>No one couldeven think about it</span>
      </div>
      <div className='broadcast__content'>
        {stream_link ? (
          <ReactPlayer url={stream_link} width='100%' height='100%' />
        ) : (
          <h2 style={{ wordBreak: 'break-all', textAlign: 'center', verticalAlign: 'middle' }}>
            NO BROADCAST
          </h2>
        )}
      </div>
      <div className='broadcast__footer'>
        <div className='broadcast__results'>
          <div className='broadcast__results-title'>Outcome</div>
          {pets_profits.length > 0 &&
            pets_profits.map((pet) => {
              const profit = Math.abs(pet.profit).toFixed(0);
              return (
                <div className='broadcast_result'>
                  <div className='broadcast__pet-info'>
                    <span className='broadcast__pet-name'>{pet.pet_name}</span>
                    <hr className='broadcast__delimiter' />
                    <span
                      className={classNames('broadcast__result-status', {
                        lose: pet.profit < 0,
                        win: pet.profit >= 0,
                      })}
                    >
                      {pet.profit < 0 ? 'lose' : 'win'}
                    </span>
                  </div>
                  <div
                    className={classNames('broadcast__result-value', {
                      lose: pet.profit < 0,
                      win: pet.profit >= 0,
                    })}
                  >
                    {pet.profit < 0 ? `- ${profit}` : `+ ${profit}`} $
                  </div>
                </div>
              );
            })}
          {pets_profits.length === 0 && <h3>NO PREVIOUS BROADCASTS</h3>}
        </div>
      </div>
    </div>
  );
};
