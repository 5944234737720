import axios from 'axios';
import { apiURL } from '../constants/apiURL';

export interface IPets {
  name: string;
  image: string;
  accuracy: string;
  biggest_win: number;
  total_balance: number;
  total_votes: number;
  today_choice: 0 | 1;
  number_of_winners: number;
  wallet_addr: string;
  email: string;
}

const pets = axios.create({
  baseURL: apiURL + 'pets',
  withCredentials: false,
});

export const petsAPI = {
  createPet: (name: string, image: string) => {
    return pets.post<IPets>(``, {
      name: name,
      image: image,
    });
  },

  getAllPets: () => {
    return pets.get<IPets[]>('', { params: { page: 0, num: 3 } });
  },

  getPetByName: (petName: string) => {
    return pets.get<IPets>(`/${petName}`);
  },

  updatePet: ({
    petName,
    today_choice,
    access_token,
    token_type,
  }: {
    petName: string;
    today_choice: number;
    access_token: string;
    token_type: string;
  }) => {
    return pets.put(
      `/${petName}`,
      {
        today_choice: today_choice,
      },
      { headers: { Authorization: `${token_type} ${access_token}` } },
    );
  },
};
