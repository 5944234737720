import axios from 'axios';
import { apiURL } from '../constants/apiURL';

export interface ISession {
  start_price?: number;
  final_price?: number;
  is_stream_live?: boolean;
  stream_link?: string;
  is_voting_allowed?: boolean;
  voting_duration?: number;
  started_at?: string;
  ended_at?: string;
  voting_started_at?: string;
  voting_ended_at?: string;
}

const session = axios.create({
  baseURL: apiURL + 'session',
  withCredentials: false,
});

export const sessionAPI = {
  getCurrentSession: async () => {
    return (await session.get<ISession[]>('', { params: { num: 1, page: 0 } })).data[0];
  },

  getPreviousSession: async () => {
    return (await session.get<ISession[]>('', { params: { num: 2, page: 0 } })).data[1];
  },

  updateCurrentSession: ({
    access_token,
    fix_session_end,
    fix_session_start,
    start_voting,
    token_type,
    update_session,
    voting_duration,
    stream_link,
    is_stream_live,
  }: {
    fix_session_start?: boolean;
    fix_session_end?: boolean;
    update_session?: boolean;
    start_voting?: boolean;
    access_token?: string;
    token_type?: string;
    voting_duration?: number;
    stream_link?: string;
    is_stream_live?: boolean;
  }) => {
    return session.put<ISession>(
      '',
      { stream_link, is_stream_live },
      {
        headers: { Authorization: `${token_type} ${access_token}` },
        params: {
          fix_session_start: fix_session_start,
          fix_session_end: fix_session_end,
          update_session: update_session,
          start_voting: start_voting,
          voting_duration: voting_duration,
        },
      },
    );
  },
};
