import classNames from 'classnames';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import './MenuItem.scss';

export interface MenuItemProps extends PropsWithChildren {
  onClick?: MouseEventHandler;
  href?: string;
}

export const MenuItem = ({ href, onClick, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href ?? '/'}
      onClick={onClick}
      className={({ isActive }) => classNames('menu-item', { 'menu-item__active': isActive })}
      end
    >
      {children}
    </NavLink>
  );
};
