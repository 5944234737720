import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ReactComponent as ArrowUp } from '../../../icons/arrow-trend-up.svg';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-trend-down.svg';
import { historiesState } from '../../../states/historiesState';
import './Results.scss';

export const Results = () => {
  const histories = useRecoilValue(historiesState);
  const dateFormatter = new Intl.DateTimeFormat(['en-US']).format;
  const [viewAll, setViewAll] = useState(false);
  const petNames = histories[0]?.pets_profits.map((profit) => (
    <div key={profit.pet_name}>{profit.pet_name}</div>
  ));
  return (
    <section className='results'>
      <div className='container'>
        <div className='results__container'>
          <h3 className='results__title'>Results</h3>
          <div className='results__table-wrap'>
            <div className='results__table results-table'>
              <div className='results-table__row results-table_head'>
                <div className='results-table__date'>Date</div>
                <div className='results-table__stop'>BTC stop</div>
                <div className='results-table__result'>BTC result</div>
                {petNames}
              </div>
              {histories
                .filter((history, index) => index < 5 || (viewAll && index < 100))
                .map((history, index) => {
                  return (
                    <div className='results-table__row text' key={index}>
                      <div className='results-table__date'>{dateFormatter(new Date(history.date))}</div>
                      <div className='results-table__stop'>{history.start_price.toFixed(0)}</div>
                      <div className='results-table__result'>{history.final_price.toFixed(0)}</div>
                      {history.pets_profits.map((profit) => (
                        <div key={profit.pet_name}>
                          {profit.profit > 0 ? (
                            <ArrowUp className={'first-pet-card__arrow'} />
                          ) : (
                            <ArrowDown className={'first-pet-card__arrow'} />
                          )}
                          {profit.profit.toFixed(0)} $
                        </div>
                      ))}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <button className='results__viewall' onClick={() => setViewAll(!viewAll)}>
          {!viewAll ? 'View all' : 'Hide'}
        </button>
      </div>
    </section>
  );
};
