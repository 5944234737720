import { InfoScreen } from '../../components/InfoScreen/InfoScreen';
import { ShareScreen } from '../../components/ShareScreen/ShareScreen';
import { Sponsored } from '../../components/Sponsored/Sponsored';
import { SubscribeForm } from '../../components/SubscribeForm/SubscribeForm';
import { InfoSlider } from './InfoSlider/InfoSlider';
import { LeaderboardScreen } from './LeaderboardScreen/LeaderboardScreen';
import { VoteScreen } from './VoteScreen/VoteScreen';

import './AnimalsPage.scss';

export const AnimalsPage = () => {
  return (
    <>
      <VoteScreen />
      <InfoScreen />
      <LeaderboardScreen />
      <ShareScreen />
      <SubscribeForm />
      <InfoSlider />
      <Sponsored />
    </>
  );
};
