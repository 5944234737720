import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { historiesAPI } from '../../api/histories';
import { ReactComponent as DownArrow } from '../../icons/arrow-down.svg';
import { ReactComponent as UpArrow } from '../../icons/arrow-up.svg';
import { profileState } from '../../states/profileState';
import { userHistories } from '../../states/userHistories';

import './ResultsHistory.scss';

export const ResultsHistory = () => {
  const profile = useRecoilValue(profileState);

  const [userHistory, setUserHistory] = useRecoilState(userHistories);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        const { data: newHistories } = await historiesAPI.getUserSessions(profile?.wallet_addr!);
        setUserHistory(newHistories);
      } catch (e) {
        console.error(e);
      }
    };
    bootstrap();
  }, [profile]);
  return (
    <div className='results-history__container'>
      <div className='results-history__controls-wrap'>
        <h3 className='results-history__title'>Rates history</h3>
        {/* <select name='' id='' className='results-history__filter'>
          <option value='all'>All</option>
          <option value='other option'>Other option</option>
        </select> */}
      </div>
      <div className='results-history__table-wrap'>
        <div className='results-history__table results-history-table'>
          <div className='results-history-table__row results-history-table_head'>
            <div className='results-history-table__cell'>Vote number</div>
            <div className='results-history-table__cell results-history-table__date'>Date</div>
            <div className='results-history-table__cell'>Choice</div>
            <div className='results-history-table__cell results-history-table__result'>Result</div>
            <div className='results-history-table__cell'>Bonuses</div>
          </div>
          {userHistory.map((item) => {
            return (
              <div className='results-history-table__row text' key={item.id}>
                <div className='results-history-table__cell results-history-table__id'>{item.id}</div>
                <div className='results-history-table__cell results-history-table__date'>
                  {new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}
                </div>
                <div className='results-history-table__cell'>
                  {item.users_choice.pet_name}{' '}
                  {item.users_choice.pet_choice === 0 && <DownArrow width={20} height={20} />}
                  {item.users_choice.pet_choice === 1 && <UpArrow width={20} height={20} />}
                </div>
                <div className='results-history-table__cell results-history-table__result'>
                  {item.is_pet_won === true ? 'won' : 'lose'}
                </div>
                <div className='results-history-table__cell'>{item.points_profit}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
