import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import './InfoScreen.scss';

export const InfoScreen = () => {
  return (
    <section className='info-screen container'>
      <h2 className='info-screen__title'>Vote for your favorites and get prizes</h2>
      <div className='info-screen__content-wrap'>
        <div className='info-screen__content-block'>
          <h4 className='info-screen__block-title'>Vote and support</h4>
          <span className='info-screen__block-text'>
            You can support our pets and vote for their choice every day. You can only bet on one animal per
            day. If the choice of the pet you supported is correct, this is your overall victory!
          </span>
        </div>
        <div className='info-screen__content-block'>
          <h4 className='info-screen__block-title'>The more the better</h4>
          <span className='info-screen__block-text'>
            The more bets you win, the more chances you have to win a prize in our charity game.
          </span>
        </div>
        <div className='info-screen__content-block info-screen__content-block_big'>
          <div className='info-screen__block-header'>
            <h3 className='info-screen__block-title'>
              <span className='info-screen__green'>100 participants</span> with the best results for the show
              will share the prize pool of <span className='info-screen__green'>$15,000</span>. Have fun
              and win!
            </h3>
            <img src='/images/coins.png' alt='coins' className='info-screen__content-img' />
          </div>
          <div className='info-screen__block-footer'>
            <Link to={'/animals#header'}>
              <Button isBig>Place a bet</Button>
            </Link>
            <p className='info-screen__vote-text'>You can only vote for one pet</p>
          </div>
        </div>
      </div>
    </section>
  );
};
