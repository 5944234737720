import { MouseEvent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import './Button.scss';

export interface ButtonProps extends PropsWithChildren {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isBig?: boolean;
  isFull?: boolean;
  disabled?: boolean;
}

export const Button = ({ className, onClick, isBig, children, isFull, disabled }: ButtonProps) => {
  return (
    <button
      className={classNames(className, 'button', {
        button_big: isBig,
        'button_full-width': isFull,
        button_disabled: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <span className='button__label'>{children}</span>
    </button>
  );
};
