import { useRecoilState } from 'recoil';
import { HeroCard } from '../../../components/HeroCard/HeroCard';
import { petsState } from '../../../states/petsState';
import './Heroes.scss';

export const Heroes = () => {
  const [pets] = useRecoilState(petsState);

  return (
    <div className='heroes'>
      <div className='heroes__container container'>
        <h2 className='heroes__title'>Our heroes</h2>
        <p className='heroes__subtitle'>You can only vote for one pet!</p>

        {pets && pets.map((pet) => <HeroCard pet={pet} key={pet.name} />)}
      </div>
    </div>
  );
};
